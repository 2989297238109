<template>
    <div>
        <router-link
                :to="{
                    name: survey.type==='api-form'?'dashboard.voiceform.analyze-form':'dashboard.voiceform.show',
                    params: {
                        surveyId: survey.id,
                    },
                }"
            >
            <v-hover v-slot="{ hover }">
                <v-card 
                    class="ma-4 d-flex flex-column" 
                    outlined 
                    width="176" 
                    height="208"
                    :class="{ 'card-hover': hover }"
                >
                    <div class="card-cover" :style="{backgroundColor: coverBackground}">
                        <div class="card-cover-title text-subtitle-1 pa-4" :style="{color: coverColor}" >{{surveyName}}</div>
                    </div>
                    <v-divider></v-divider>
                    <v-toolbar elevation="0">
                        <div class="text-body-2">{{responsesText}}</div>
                        <v-spacer></v-spacer>
                        <v-menu
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" @click.prevent v-on="on" icon>
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="$emit('copySurveyLink', survey, false)">
                                    <v-icon small class="mr-2"> mdi-share</v-icon>
                                    <v-list-item-title class="text-body-2">Copy sharable link</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="$emit('copySurveyLink', survey, true)">
                                    <v-icon small class="mr-2"> mdi-pencil</v-icon>
                                    <v-list-item-title class="text-body-2">Copy editor link</v-list-item-title>
                                </v-list-item>
                                <v-list-item :disabled="!enableDuplicate" @click="$emit('duplicateSurvey',survey.id)">
                                    <v-icon small class="mr-2"> mdi-content-duplicate</v-icon>
                                    <v-list-item-title class="text-body-2">Duplicate</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="$emit('openDialog',survey)">
                                    <v-icon small class="mr-2">mdi-delete</v-icon>
                                    <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                    
                </v-card>
            </v-hover>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        survey: {
            required: true
        },
        enableDuplicate: {
            required: true
        }
    },
    computed: {
        coverBackground(){
            return (this.survey && this.survey.style) ? this.survey.style.background : '#ffffff'
        },
        coverColor(){
            return (this.survey && this.survey.style) ? this.survey.style.color : '#000000'
        },
        surveyName(){
            const name = this.survey.name || 'No name'
            if(name.length > 34){
                return `${name.slice(0,34)}...`
            }
            return name
        },
        responsesText(){
           const responses = this.survey.results_count
           if(!responses){
               return 'No responses'
           }
           return `${responses} responses`
        },
    }
}
</script>

<style lang="scss" scoped>
.card{
    &-cover {
        display: flex;
        height: 148px;
        align-items: center;
        &-title{
            word-break: break-word;
        }
    }
    &-hover {
        border-color: var(--v-primary-base);
    }
}
</style>