<template>
    <home-page-layout>
        <v-app-bar height="85" :style="`margin-left: ${this.$vuetify.application.left}px`" fixed
            color="grey lighten-5 header px-8 py-0" elevation="0">
            <div class="d-flex flex-column flex-grow-1">
                <div class="mt-6 d-flex flex-grow-1">
                    <v-btn :disabled=" accessBlocked || activeMembership && activeMembership.status === 'blocked'"
                        @click="createOptionsDialog = true" elevation="0" color="primary">
                        <v-icon left>mdi-plus</v-icon>
                        Create voiceform
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn-toggle :small="$vuetify.breakpoint.xs" v-model="layout" dense borderless background-color="grey lighten-2" mandatory>
                        <v-btn value="grid" min-width="90px">
                            <v-icon :left="!$vuetify.breakpoint.xs">mdi-grid</v-icon>
                            <span v-if="!$vuetify.breakpoint.xs">Grid</span>
                        </v-btn>

                        <v-btn value="list" min-width="90px">
                            <v-icon :left="!$vuetify.breakpoint.xs">mdi-format-list-bulleted</v-icon>
                            <span v-if="!$vuetify.breakpoint.xs">List</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <v-divider class="mt-6"></v-divider>
            </div>
        </v-app-bar>
        <v-snackbar :timeout="-1" :value="[STATES.ERROR, STATES.STALE_IF_ERROR].includes(state)" bottom color="error">
            Can't load surveys. Please try again later.
        </v-snackbar>
        <v-container v-if="[STATES.PENDING].includes(state)" fluid class="main-container px-8 d-flex">
            <div class="d-flex flex-grow-1 mx-n4 flex-wrap">
                <div class="ma-4" style="width:176px; height:208px;" v-for="index in 5"
                    :key="`form-card-placeholder${index}`">
                    <ContentLoader :primaryColor="$vuetify.theme.themes.light.grey.lighten4"
                        :secondaryColor="$vuetify.theme.themes.light.grey.lighten3" :primaryOpacity="0.9"
                        :secondaryOpacity="0.6" width="176" height="208" viewBox="0 0 176 208">
                        <rect x="0" y="0" rx="3" ry="3" width="176" height="208" />
                    </ContentLoader>
                </div>
            </div>
        </v-container>
        <v-container fluid v-else-if="!accessBlocked && forms && forms.length" class="main-container px-8 d-flex">
            <template v-if="layout === 'grid'">
                <div class="d-flex flex-grow-1 mx-n4 flex-wrap">
                    <form-card-item v-for="survey in forms" :key="survey.id" :survey="survey"
                        @copySurveyLink="copySurveyLink" @duplicateSurvey="handleDuplicate" @openDialog="openDialog"
                        :enableDuplicate="enableDuplicate">
                    </form-card-item>
                </div>
            </template>

            <template v-else>
                <v-data-table class="table mt-4 mb-8" :headers="headers" :items="forms" :sort-by="['created_at']"
                    :sort-desc="[true]" :items-per-page="-1" hide-default-footer @click:row="handleTableRowClick">
                    <template v-slot:[`item.name`]="{ item }">
                        <div class="d-flex align-center my-6">
                            <div class="raw-icon mr-4" :style="{ backgroundColor: getCoverBackground(item) }">
                                <v-icon size="24" :color="getCoverColor(item)">
                                    $form
                                </v-icon>
                            </div>
                            <div class="text-subtitle-1">{{ item.name }}</div>
                        </div>
                    </template>
                    <template v-slot:[`item.responses`]="{ item }">
                        {{ item.results_count || 0 }} responses
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ $date(item.created_at).format('MMM D, YYYY') }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined color="secondary" v-bind="attrs" @click.prevent v-on="on">
                                    <v-icon left>mdi-dots-horizontal</v-icon>
                                    More
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="copySurveyLink(item, false)">
                                    <v-icon small class="mr-2"> mdi-share</v-icon>
                                    <v-list-item-title class="text-body-2">Copy sharable link</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="copySurveyLink(item, true)">
                                    <v-icon small class="mr-2"> mdi-pencil</v-icon>
                                    <v-list-item-title class="text-body-2">Copy editor link</v-list-item-title>
                                </v-list-item>
                                <v-list-item :disabled="!enableDuplicate" @click="handleDuplicate(item.id)">
                                    <v-icon small class="mr-2"> mdi-content-duplicate</v-icon>
                                    <v-list-item-title class="text-body-2">Duplicate</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openDialog(item)">
                                    <v-icon small class="mr-2">mdi-delete</v-icon>
                                    <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </template>

        </v-container>

        <v-container v-else-if="accessBlocked || activeMembership && activeMembership.status === 'blocked'" class="main-container px-8">
            <div class="d-flex mt-12 mb-6 flex-column align-center text-center">
                <img src="https://cdn.voiceform.com/media/blocked-illustration.svg" height="336" style="height: 336px;" />
                <div class="mt-6 text-h6">Access Denied</div>
                <div class="mt-6" style="max-width: 400px;">To access this team, the team owner, <a
                        :href="`mailto:${owner.user.email}?subject=Voiceform%20Upgrade`">{{ owner.user.email }}</a>, will
                    need
                    to upgrade their account.</div>
            </div>
        </v-container>
        <v-container v-else class="main-container px-8">
            <div class="d-flex mt-12 mb-6 flex-column align-center">
                <img src="https://cdn.voiceform.com/media/discover-new-illustration.svg" height="336"
                    style="height: 336px;" />
                <div class="mt-6 text-h6">No voiceforms here yet</div>
                <div class="mt-6">Create a new voiceform and discover something new!</div>
                <v-btn @click="createOptionsDialog = true" class="ml-n4 mt-6" color="secondary" elevation="0">
                    <v-icon left>mdi-plus</v-icon>
                    Create new voiceform
                </v-btn>
            </div>
        </v-container>
        <v-dialog v-model="deleteDialog" width="500" @click:outside="closeDeleteDialog">
            <v-card class="remove-dialog pt-4 pb-3 px-6">
                <h3>
                    Delete Survey
                </h3>
                <p>
                    Are you sure you want to delete <b>{{ deletingSurveyName }}</b> survey? Once deleted, you will not be
                    able
                    to recover this survey or its contents.
                </p>
                <v-text-field :label='`Type  "${deletingSurveyName}" to confirm`' v-model="deleteConfirmation"
                    outlined></v-text-field>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDeleteDialog">
                        Cancel
                    </v-btn>
                    <v-btn color="red" dark :elevation="0" @click="deleteSurvey">
                        Yes, Delete it
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="createFormDialog" width="400">
            <create-form-dialog @close="createFormDialog=false"></create-form-dialog>
        </v-dialog>

        <v-dialog v-model="createOptionsDialog" width="800">
            <v-card class="pa-8">
                <div class="d-flex flex-grow-1">
                    <h5 class="text-h5">
                        Create a new voiceform
                    </h5>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-n4" @click="createOptionsDialog = false" text color="primary">Close</v-btn>
                </div>
                <div class="mt-8 d-flex">
                    <v-card hover @click="handleStartFromScratch" width="240" height="300"
                        class="pa-6" outlined>
                        <img src="https://cdn.voiceform.com/media/scratch-illustration.svg"
                            style="width: 192px; height: 130px;" width="192px" height="130px" />
                        <div class="text-h6 mt-4">Start from scratch</div>
                        <div class="mt-4 text-body-2">Jump right in and create something awesome!</div>
                    </v-card>
                    <v-spacer></v-spacer>
                    <v-card :to="{ name: 'dashboard.voiceform.templates' }" @click="handleTemplateSelect" hover width="240" height="300" class="pa-6"
                            outlined>
                            <img src="https://cdn.voiceform.com/media/template-illustration.svg"
                                style="width: 200px; height: 130px;" width="200px" height="130px" />
                            <div class="text-h6 mt-4">Use a template</div>
                            <div class="mt-4 text-body-2">Select a pre-made voiceform and customize it as you want</div>
                        </v-card>
                    <v-spacer></v-spacer>
                    <v-card hover width="240" height="300" class="pa-6" outlined @click="openGenerateAiFormDialog">
                        <img src="https://cdn.voiceform.com/media%2Fform-auto-generation.svg"
                            style="width: 200px; height: 130px;" width="200px" height="130px" />
                        <!-- <v-chip label small color="purple accent-3" dark class="mr-2 beta-chip">Beta</v-chip> -->
                        <div class="text-h6 mt-4">Auto-Generate</div>
                        <div class="mt-4 text-body-2">Generate a voiceform using AI. Unlock full customization</div>
                    </v-card>
                </div>
            </v-card>
        </v-dialog>

        <generate-ai-form-dialog :visible="showGenerateAiFormDialog" @close="showGenerateAiFormDialog = false">
        </generate-ai-form-dialog>
    </home-page-layout>
</template>

<script>
import { watch, computed} from 'vue'
import useSwrv from 'swrv'
import { mapGetters, mapMutations } from "vuex";
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import HomePageLayout from "../../layouts/HomePageLayout.vue";
import FormCardItem from './FormCardItem.vue';
import { ContentLoader } from 'vue-content-loader'
import GenerateAiFormDialog from './GenerateAiFormDialog.vue';
import fetcher from '../../api/fetcher'
import useSwrvState from '@/utils/useSwrvState'
import { destroy, duplicate } from '../../api/survey';
import { useRoute } from 'vue-router/composables';
import CreateFormDialog from './CreateFormDialog.vue';

export default {
    name: "AllVoiceforms",
    components: { HomePageLayout, ContainerCard, FormCardItem, ContentLoader, GenerateAiFormDialog, CreateFormDialog },
    data() {
        return {
            deleteDialog: false,
            createOptionsDialog: false,
            createFormDialog: false,
            showGenerateAiFormDialog: false,
            deleteConfirmation: '',
            deletingSurvey: null,
            layout: this.$route.query.layout || 'grid',
            headers: [
                {
                    text: 'Form Name',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Responses',
                    align: 'end',
                    value: 'responses',
                    sortable: false,
                },
                { text: 'Date created', value: 'created_at', align: 'end' },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            surveys: "survey/surveys",
            loadingSurveys: "survey/loadingSurveys",
            features: 'auth/features',
            activeMembership: 'teams/activeMembership'
        }),
        deletingSurveyName() {
            if (this.deletingSurvey) {
                return this.deletingSurvey.name ? this.deletingSurvey.name : this.deletingSurvey.title
            }
            return ''
        },

        enableDuplicate() {
            return this.features && this.features.forms_duplication
        },
        owner() {
            if (this.activeMembership) {
                return this.activeMembership.team.memberships.find(membership => membership.role === 'owner')
            }
            return null
        }
    },
    watch: {
        layout(value) {
            this.$router.push({ query: { ...this.$route.query, layout: value } })
        },
        createOptionsDialog(value){
            _cio.track(value?"create_form_options_dialog.open":"create_form_options_dialog.close");
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        openGenerateAiFormDialog() {
            _cio.track("create_form_options.selected", { option: "auto_generate" });
            this.createOptionsDialog = false;
            this.showGenerateAiFormDialog = true;
        },
        handleStartFromScratch(){
            _cio.track("create_form_options.selected", { option: "start_from_scratch" })
            this.createOptionsDialog = false;
            this.createFormDialog = true;
        },
        handleTemplateSelect(){
            _cio.track("create_form_options.selected", { option: "template" })
        },

        closeDeleteDialog() {
            this.deleteDialog = false;
            this.deleteConfirmation = ''
            this.deletingSurvey = null
        },
        async copySurveyLink(survey, editorLink) {
            try {
                if (editorLink) {
                    await navigator.clipboard.writeText(window.location.origin + "/dashboard/voiceform/" + survey.id + "/create");
                } else {
                    await navigator.clipboard.writeText(window.location.origin + "/to/" + survey.hash);
                }
            } catch (err) {
                console.error(err);
            }
            this.showSnackBar({
                color: "success",
                timeout: 1000,
                text: "Survey link copied."
            });
        },
        openDialog(survey) {
            this.deletingSurvey = survey
            this.deleteDialog = true;
        },
        async deleteSurvey() {
            const surveyNames = [
                this.deletingSurvey.name ? this.deletingSurvey.name.replace(/\s+/g, ' ') : '',
                this.deletingSurvey.title ? this.deletingSurvey.title.replace(/\s+/g, ' ') : ''
            ];
            if (surveyNames.includes(this.deleteConfirmation)) {
                await destroy(this.deletingSurvey.id)
                this.mutate()
                this.closeDeleteDialog()
            }
        },
        handleTableRowClick(survey) {
            this.$router.push({
                name: survey.type === 'api-form' ? 'dashboard.voiceform.analyze-form' : 'dashboard.voiceform.show',
                params: {
                    surveyId: survey.id,
                },
            })
        },
        getCoverBackground(survey) {
            return (survey && survey.style) ? survey.style.background : '#ffffff'
        },
        getCoverColor(survey) {
            return (survey && survey.style) ? survey.style.color : '#000000'
        },
        async handleDuplicate(surveyId) {
            await duplicate(surveyId)
            this.mutate()
        }

    },
    setup() {
        const route = useRoute(); // Use the current route
        const teamId = computed(() => route.params.teamId); // Make teamId a computed property

        const { data, error, isValidating, mutate } = useSwrv(
            () => teamId.value ? `/api/teams/${teamId.value}/forms` : '/api/forms', // Access the value of the computed property
            fetcher
        );
        const { state, STATES } = useSwrvState(data, error, isValidating);


        // Watch for changes in the teamId parameter and revalidate the data
        watch(() => route.params.teamId, (newTeamId) => {
            if (newTeamId !== teamId.value) { // Access the value of the computed property
                mutate(); // Trigger revalidation when the teamId parameter changes
            }
        });

        const accessBlocked = computed(() => error.value && error.value.response.status === 403);

        return { state, STATES, forms: data, error, mutate, accessBlocked };
    },
};
</script>

<style lang="scss" scoped>
.table {
    flex: 1;
    background-color: transparent;
}

.remove-dialog {
    font-size: 16px;
}

.header {
    align-items: flex-start;
    top: 64px !important;
}

.header::v-deep .v-toolbar__content {
    padding: 0;
    align-items: flex-start;
}

.main-container {
    padding-top: 85px;
}

.raw-icon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.beta-chip {
    position: absolute;
    top: 10px;
    border-radius: 8px !important;
    padding: 5px !important;
}
</style>

