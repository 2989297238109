<template>
    <v-card v-if="isActiveFreeTrial" class="d-flex" outlined color="primary lighten-5 mb-6 ">

        <div class="d-flex flex-column pa-4">
            <div class="text-subtitle-1 mb-2">Your Plan</div>
            <div class="body-2 mb-6">You are currently on a free trial</div>
            <div class="d-flex align-center mb-4">
                <div class="text-subtitle-1 mr-2">{{trialDaysPeriod}}</div>
                <div class="text-body-2">days left</div>
            </div>
            <v-btn outlined elevation="0" :to="{name: 'dashboard.upgrade'}" color="primary">Buy Now</v-btn>
        </div>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        isActiveFreeTrial(){
           return  !!this.user && !!this.user.trial_ends_at && this.$date().isBefore(this.user.trial_ends_at)
        },

        trialDaysPeriod(){
            if(this.isActiveFreeTrial){
                return Math.ceil(this.$date(this.user.trial_ends_at).diff(this.$date(), 'day', true))
            }
            return null
        },
    }
}
</script>