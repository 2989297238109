<template>
    <div>
        <div class="d-flex align-center">
            <v-btn :disabled="!availableSeats" @click="handleDialogOpen" small outlined color="primary">
                <v-icon left>mdi-plus</v-icon>
                Invite Members
            </v-btn>
            <div v-if="!availableSeats" class="ml-2 caption error--text text--darken1">The seats limit has been reached.</div>
        </div>
        <v-dialog 
            v-model="inviteMembersDialog"
            width="500"
            :persistent="submittingForm"
        >
            <v-card class="py-6">
                <div class="px-6">
                    <div class="text-h6 mb-4">Invite team members</div>
                    <div class="mb-4">Just enter the email addresses of the people you'd like to invite and we'll send them an email.</div>
                    <div class="text-body-2 mb-4"><span class="subtitle-2">{{emails.length}}/{{availableSeats}}</span> of available seats</div>
                    <div>
                        <input-tag :limit="availableSeats" add-tag-on-blur class="text-body-1" validate="email" :add-tag-on-keys="[13, 188, 9, 32]" placeholder="Enter email addresses" v-model="emails"></input-tag>
                    </div>
                </div>
                <div class="d-flex pt-4 px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="handleDialogClose"
                        class="mr-4"
                        v-if="!submittingForm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :elevation="0"
                        @click="handleSubmit"
                        :loading="submittingForm"
                        :disabled="!emails.length || submittingForm"
                    >
                        {{submitText}}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
import InputTag from 'vue-input-tag'
import { mapActions, mapMutations } from 'vuex'
export default {
    components: {InputTag},
    props: ['membership'],
    data(){
        return {
            submittingForm: false,
            inviteMembersDialog: false,
            emails: [],
        }
    },
    computed:{
        submitText(){
            if(!this.emails.length){
                return 'Invite Team Members'
            }
            if(this.emails.length === 1){
                return 'Invite 1 Team Member'
            }
            
            return `Invite ${this.emails.length} Team Members`
            
        },

        availableSeats(){
            if(this.membership){
                const ownerMembership = this.membership.team.memberships.find(membership=>membership.role==='owner')
                if(ownerMembership){
                    const diff = ownerMembership.user.seats_limit - ownerMembership.user.total_seats
                    return diff< 1 ? 0 : diff
                }
            }
            return 0
        }
    },
    methods: {
        ...mapActions({
            inviteToTeam: 'teams/inviteToTeam'
        }),
        ...mapMutations(['showSnackBar']),
        async handleSubmit(){
            try {
                this.submittingForm = true
                const invites = this.emails.map(email => ({role: 'member', email}))
                const res = await this.inviteToTeam({teamId: this.membership.team.id, invites})
                this.submittingForm = false
                if(res && res.status ===200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "New team members have been invited!",
                    });

                    this.handleDialogClose()
                }
            } catch (error) {
                this.submittingForm = false
                let errorText = 'Cannot invite these users. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
        },
        handleDialogOpen(){
            this.emails = []
            this.inviteMembersDialog = true
        },
        handleDialogClose(){
            this.inviteMembersDialog = false
        }
    }
}
</script>