<template>
    <v-app>
        <dashboard-header></dashboard-header>
        <v-navigation-drawer :permanent="!$vuetify.breakpoint.xs" class="pt-6" clipped width="222" app>
            <div class="d-flex fill-height flex-column">
                <div class="mx-6">
                    <active-team></active-team>
                </div>
                <v-spacer></v-spacer>
                <v-divider class="my-4" v-if="showBottomSection"></v-divider>
                <div class="mx-6 pb-6" v-if="showBottomSection">
                    <side-panel-usage v-if="!hideUsage"></side-panel-usage>
                    <free-trial-widget v-else></free-trial-widget>
                </div>
            </div>
        </v-navigation-drawer>
        
        <v-main color="#FAFAFA" class="main">
            <v-container class="pa-0 pb-16" fluid>
                <slot></slot>
            </v-container>
        </v-main>
        <invite-popup v-if="invites && invites.length"></invite-popup>

        <div id="voiceform-container"></div>
        <switch-to-desctop-banner></switch-to-desctop-banner>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import FreeTrialWidget from './FreeTrialWidget.vue';
import DashboardHeader from './DashboardHeader.vue';
import SidePanelUsage from './SidePanelUsage.vue';
import ActiveTeam from './ActiveTeam.vue';
import InvitePopup from '../views/teams/InvitePopup.vue';
import { Crisp } from "crisp-sdk-web";
import SwitchToDesctopBanner from './SwitchToDesctopBanner.vue';

export default {
    components: { SidePanelUsage, DashboardHeader, FreeTrialWidget, ActiveTeam, InvitePopup, SwitchToDesctopBanner },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            activeMembership: 'teams/activeMembership',
            invites: 'teams/invites',
        }),
        showBottomSection(){
            if(this.activeMembership && this.activeMembership.role !== 'owner'){
                return false
            }
            return true
        },
        hideUsage(){
            return !!this.user && !!this.user.trial_ends_at && this.$date().isBefore(this.user.trial_ends_at)
        },
    },
    created(){
        Crisp.chat.show();
    }
}
</script>

<style lang="scss">
    :root {
        --overflow: unset;
    }
</style>
<style scoped>
.main{
    background-color: #FAFAFA;
}
</style>