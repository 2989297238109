<template>
    <tr height="42" class="text-body-2 py-2 member-row">
        <td>{{email}}</td>
        <td style="text-transform: capitalize;">{{role}}</td>
        <td :class="[{'error--text text--darken1': status==='blocked'}]" style="text-transform: capitalize;">
            <v-tooltip v-if="status==='blocked'" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" style="cursor: default;" v-bind="attrs" v-on="on">
                        <v-icon size="16"  class="mr-1" color="error">mdi-alert-outline</v-icon>
                        <span>{{status}}</span>
                    </div>
                </template>
                <div style="width: 200px;">To access this team, the team owner will need to upgrade their account.</div>
            </v-tooltip>
            <span v-else>{{status}}</span>
        </td>
        <td v-if="membership.role !== 'member'" style="text-align: right;">
            <v-menu
                offset-x
                offset-overflow
                v-if="role!=='owner'"
                :close-on-content-click="false"
                v-model="menu"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="loading" :loading="loading" v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                </template>
                <v-list flat dense>
                    <v-list-item-group>
                        <v-list-item @click="handleResendInvite" v-if="status==='pending'">
                            <v-list-item-icon class="mr-2"><v-icon>mdi-send</v-icon></v-list-item-icon>
                            <v-list-item-title class="text-body-2">Resend invitation</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="handleSwitchRole">
                            <v-list-item-icon class="mr-2"><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
                            <v-list-item-title class="text-body-2">Switch role to {{member.role==='admin'?'Member':'Admin'}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="handleRemoveClick" color="error">
                            <v-list-item-icon class="mr-2"><v-icon color="error">mdi-delete</v-icon></v-list-item-icon>
                            <v-list-item-title class="text-body-2 error--text">Remove member</v-list-item-title>
                        </v-list-item>
                        <v-overlay
                            :value="!!confirmation"
                            color="white"
                            :opacity="0.9"
                        >
                            <div class="text-body-1 mb-2 primary--text">Really?</div>
                            <v-btn @click="handleConfirmationClick" elevation="0" color="primary">yes</v-btn>
                        </v-overlay>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    data(){
        return {
            removingMember: false,
            resendingInvite: false,
            updatingMember: false,
            confirmation: null,
            menu: false
        }
    },
    props: {
        member: {
            required: true,
        },
        membership: {
            required: true
        }
    },
    watch: {
        menu(val){
            if(!val){
                this.confirmation=null
            }
        }
    },
    computed: {
        email(){
            return  (this.member.user && this.member.user.email) || this.member.invitation_email
        },
        role(){
            return this.member.role
        },
        status(){
            return this.member.is_blocked && 'Blocked' || this.member.status
        },
        loading(){
            return !!this.removingMember && !!this.resendingInvite && !!this.updatingMember
        }
    },
    methods: {
        ...mapActions({
            removeMember: 'teams/removeMember',
            resendInvite: 'teams/resendInvite',
            updateMember: 'teams/updateMember',
        }),
        ...mapMutations(['showSnackBar']),

        handleConfirmationClick(){
            switch (this.confirmation) {
                case 'remove':
                    this.handleRemoveClick()
                    break;
                case 'update':
                    this.handleSwitchRole()
                    break;
            }
            this.confirmation=null
        },

        async handleRemoveClick(){
            if(!this.confirmation){
                return this.confirmation='remove'
            }
            try {
                this.removingMember = true
                const res = await this.removeMember({teamId:  this.membership.team.id, membershipId: this.member.id })
                this.removingMember = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "The member has been removed from the team.",
                    });
                }
            } catch (error) {
                console.error(error);
                this.removingMember = false
                let errorText = 'Oooops, something went wrong. Cannot remove member from the team. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
            
        },

        async handleResendInvite(){
            try {
                this.menu=false
                this.resendingInvite = true
                const res = await this.resendInvite({teamId:  this.membership.team.id, membershipId: this.member.id })
                this.resendingInvite = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "The invite has been sent.",
                    });
                }
            } catch (error) {
                console.error(error);
                this.resendingInvite = false
                let errorText = 'Oooops, something went wrong. Cannot resend the invite. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
        },

        async handleSwitchRole(){
            try {
                if(!this.confirmation){
                    return this.confirmation='update'
                }
                this.menu=false
                this.updatingMember = true
                const data = {
                    role: this.member.role === 'admin'? 'member' : 'admin'
                }
                const res = await this.updateMember({teamId:  this.membership.team.id, membershipId: this.member.id, data })
                this.updatingMember = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "The member role has been updated.",
                    });
                }
            } catch (error) {
                console.error(error);
                this.updatingMember = false
                let errorText = 'Oooops, something went wrong. Cannot update the team member role. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.member-row{
    border-bottom: thin solid;
    border-bottom-color: var(--v-grey-lighten2);
}
</style>