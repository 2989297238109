<template>
    <div>
        <v-btn min-width="auto" width="44" height="44" class="add-question-button" elevation="0" large color="grey lighten-3" @click="handleOpenDialogue">
            <v-icon color="secondary">mdi-plus</v-icon>
        </v-btn>
        <v-dialog 
            v-model="createTeamPopup"
            width="500"
            persistent
        >
            <v-card class="py-6">
                <div class="text-h6 mb-5 px-6">
                    Create a new team!
                </div>
                <v-divider class="my-4"></v-divider>
                
                <v-form
                    ref="form"
                    lazy-validation
                    v-model="valid"
                    @submit.prevent="handleSubmit"
                    class="px-6 mb-6"
                    autocomplete="off"
                >
                    <div class="d-flex">
                        <team-icon-selector v-model="iconUrl"></team-icon-selector>
                        <div class="d-flex flex-column flex-grow-1 ml-4">
                            <div class="text-subtitle-1 mb-2">Team name</div>
                            <v-text-field 
                                outlined 
                                dense 
                                placeholder="Marketing"  
                                v-model="name"
                                :rules="nameRules"
                                validate-on-blur
                                counter="30"
                            ></v-text-field>
                        </div>
                    </div>

                    <div class="text-subtitle-1 mb-2">Team description (optional)</div>
                    <div class="text-body-2 mb-4">Share a little bit about your team and goals!</div>
                    <v-textarea 
                        outlined 
                        dense 
                        placeholder="A team of superstar marketers conducting research about consumer behavior."  
                        v-model="description"
                        validate-on-blur
                        counter="100"
                        :rules="descriptionRules"
                    ></v-textarea>
                </v-form>
                <div class="d-flex pt-4 px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="createTeamPopup=false"
                        class="mr-4"
                        v-if="!submittingForm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        :elevation="0"
                        @click="handleCreateTeam"
                        :loading="submittingForm"
                        :disabled="submittingForm"
                    >
                        Create team
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import TeamIconSelector from './TeamIconSelector.vue'
export default {
  components: { TeamIconSelector },
    data(){
        return {
            createTeamPopup: false,
            valid: true,
            name: '',
            description: '',
            submittingForm: false,
            iconUrl: '',
            nameRules: [
                value => !!value.trim() || 'The name is required',
                value => value.trim().length<=30 || 'The team name is too long'
            ],
            descriptionRules: [
                value => value.trim().length<=100 || 'The team description is too long'
            ]
        }
    },
    methods: {
        ...mapActions({
            createTeam: 'teams/createTeam'
        }),
        ...mapMutations(['showSnackBar']),
        handleOpenDialogue(){
            this.name= ''
            this.description= ''
            this.iconUrl = ''
            this.createTeamPopup = true
        },
        async handleCreateTeam(){
            try {
                const valid = this.$refs.form.validate();

                if(!valid) {
                    return;
                }


                this.submittingForm = true
                const res = await this.createTeam({
                    name: this.name.trim(),
                    description: this.description.trim(),
                    icon_url: this.iconUrl
                })
                this.submittingForm = false
                
                if(res && res.status ===200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "New team is created!",
                    });

                    this.createTeamPopup = false
                }
            } catch (error) {
                this.submittingForm = false
                this.showSnackBar({
                    text: 'Ops. Something went wrong. Please try to submit the form later or contact customer support.', 
                    color: 'error', 
                    timeout: 4000
                })
            }
        }
    }
}
</script>