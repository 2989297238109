<template>
    <div>
        <v-btn @click="handleOpenDialog" elevation="0" color="d-flex grey lighten-4 align-center justify-center" height="80" width="80">
            <v-avatar v-if="this.value" size="60" >
                <img :src="this.value" />
            </v-avatar>
            <v-icon v-else large>mdi-image-plus</v-icon>
        </v-btn>
        <v-dialog 
            v-model="iconSelectorDialog"
            width="560"
            @click:outside="handleCloseDialog"
            persistent
        >
            <v-card class="py-6 px-4">
                <div class="text-h6 mb-4">Select team icon</div>
                <div class="icons-container">
                    <div class="d-flex flex-wrap">
                        <v-btn @click="handleIconSelect(icon)" outlined :color="icon.url===selectedIconUrl?'primary':'transparent'" class="mr-4 mb-4" width="70" height="70" elevation="0" v-for="(icon, index) in teamTypes" :key="`team-type-${index}`">
                            <v-avatar size="60" >
                                <img
                                    :src="icon.url"
                                    :alt="icon.alt"
                                >
                            </v-avatar>
                        </v-btn>
                    </div>
                    <div class="text-subtitle-1 my-4">Business</div>
                    <div class="d-flex flex-wrap">
                        <v-btn @click="handleIconSelect(icon)" outlined :color="icon.url===selectedIconUrl?'primary':'transparent'" class="mr-4 mb-4" width="70" height="70" elevation="0" v-for="(icon, index) in business" :key="`team-type-${index}`">
                            <v-avatar size="60" >
                                <img
                                    :src="icon.url"
                                    :alt="icon.alt"
                                >
                            </v-avatar>
                        </v-btn>
                    </div>
                    <div class="text-subtitle-1 my-4">Food</div>
                    <v-btn @click="handleIconSelect(icon)" outlined :color="icon.url===selectedIconUrl?'primary':'transparent'" class="mr-4 mb-4" width="70" height="70" elevation="0" v-for="(icon, index) in food" :key="`team-type-${index}`">
                            <v-avatar size="60" >
                                <img
                                    :src="icon.url"
                                    :alt="icon.alt"
                                >
                            </v-avatar>
                    </v-btn>
                </div>
                <div class="d-flex pt-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="handleCloseDialog"
                        class="mr-4"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        :elevation="0"
                        @click="changeIcon"
                    >
                        Change icon
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {icons} from '../../utils/team-icons'
export default {
    props: ['value'],
    data(){
        return {
            icons,
            iconSelectorDialog: false,
            selectedIconUrl: this.value
        }
    },
    computed:{
        teamTypes(){
           return this.icons['Team Types']
        },
        business(){
            return this.icons.business
        },
        food(){
            return this.icons.food
        }
    },
    methods: {
        handleCloseDialog(){
            this.iconSelectorDialog = false
        },
        handleOpenDialog(){
            this.selectedIconUrl = this.value || ''
            this.iconSelectorDialog = true
        },
        handleIconSelect(icon){
            this.selectedIconUrl= icon.url
        },
        changeIcon(){
            this.$emit('input', this.selectedIconUrl)
            this.handleCloseDialog()
        }
    }
}
</script>

<style lang="scss" scoped>
.icons-container{
    max-height: 500px;
    overflow-y: scroll;
}
</style>