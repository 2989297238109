<template>
    <v-dialog persistent v-model="dialog" width="500">
        <v-card v-if="inviteInfo">
            <v-img 
                class="cover"
                height="200" 
                src="https://cdn.voiceform.com/media/invite-cover@2x.png"
                srcset="https://cdn.voiceform.com/media/invite-cover.png,
                        https://cdn.voiceform.com/media/invite-cover@2x.png 2x,
                        https://cdn.voiceform.com/media/invite-cover@3x.png 3x"
                >
                <div class="d-flex flex-grow-1 fill-height justify-center align-end">
                    <v-avatar color="primary lighten-4" class="mb-n7" size="120" style="border: 6px solid #eaf3ff !important;">
                        <img
                            v-if="inviteInfo.team.icon_url"
                            :src="inviteInfo.team.icon_url"
                            alt="team icon"
                        >
                        <span class="text-h5" v-else>{{inviteInfo.team.name.toUpperCase()[0]}}T</span>
                    </v-avatar>
                </div>
            </v-img>
            <div class="d-flex flex-column align-center px-6 pb-8 pt-11 text-center">
                <div class="text-h6 mb-6">Team Invitation!</div>
                <div style="max-width: 330px;" class="text-body-1 mb-10">{{message}}</div>
                <div v-if="sendingAnswer">
                    <v-progress-circular color="primary"></v-progress-circular>
                </div>
                <div class="d-flex flex-column" v-else>
                    <v-btn @click="handleAnswer(true)" class="mb-2" large elevation="0" color="primary">Accept Invitation</v-btn>
                    <v-btn @click="handleAnswer(false)" text color="error">Decline Invitation</v-btn>
                </div>
                
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return{
            dialog: false,
            sendingAnswer: false
        }
    },
    computed: {
        ...mapGetters({
            invites: 'teams/invites'
        }),
        inviteInfo(){
            return this.invites && this.invites[0]
        },
        message(){
            if(this.inviteInfo){
                const owner = this.inviteInfo.team.memberships.find(membership=> membership.role === 'owner')
                const role = this.inviteInfo.role === 'admin'? 'Admin': 'Member'
                if(owner && owner.user && owner.user.first_name && owner.user.last_name){
                    return `${owner.user.first_name} ${owner.user.last_name} has invited you (${this.inviteInfo.invitation_email}) to the "${this.inviteInfo.team.name}" team as ${role}.`
                }
                return `You have been invited to the "${this.inviteInfo.team.name}" team as ${role}.`
            }
            return ''
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            answerInvite: 'teams/answerInvite'
        }),
        checkInvitations(){
            if(this.invites && this.invites.length){
                this.dialog=true
            }
        },
        async handleAnswer(answer){
            try {
                this.sendingAnswer = true
                const res = await this.answerInvite({membershipId: this.inviteInfo.id, answer})
                this.sendingAnswer = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: `The invite has been ${answer?'accepted': 'declined'}.`,
                    });

                    this.dialog = false
                    setTimeout(()=>this.checkInvitations(), 1000);
                }
            } catch (error) {
                console.error(error);
                this.sendingAnswer = false
                let errorText = 'Oooops, something went wrong. Cannot send answer to invitation. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
        }
    },
    mounted(){
        this.checkInvitations()
    }
}
</script>

<style lang="scss" scoped>
.cover{
    overflow: unset !important;
}
</style>
