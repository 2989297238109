var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":{
                name: _vm.survey.type==='api-form'?'dashboard.voiceform.analyze-form':'dashboard.voiceform.show',
                params: {
                    surveyId: _vm.survey.id,
                },
            }}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-4 d-flex flex-column",class:{ 'card-hover': hover },attrs:{"outlined":"","width":"176","height":"208"}},[_c('div',{staticClass:"card-cover",style:({backgroundColor: _vm.coverBackground})},[_c('div',{staticClass:"card-cover-title text-subtitle-1 pa-4",style:({color: _vm.coverColor})},[_vm._v(_vm._s(_vm.surveyName))])]),_c('v-divider'),_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.responsesText))]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('copySurveyLink', _vm.survey, false)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-share")]),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v("Copy sharable link")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('copySurveyLink', _vm.survey, true)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil")]),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v("Copy editor link")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.enableDuplicate},on:{"click":function($event){return _vm.$emit('duplicateSurvey',_vm.survey.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-content-duplicate")]),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v("Duplicate")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('openDialog',_vm.survey)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v("Delete")])],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }