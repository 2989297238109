<template>
    <div>
        <div v-if="loading" class="d-flex align-center justify-center flex-column" style="height: 250px;">
            <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div class="text-h6 mt-6">
                Generating a form
            </div>
            <div class="body-1 mt-6">
                This might take a minute. Sit tight.
            </div>
        </div>
        <div v-else>
            <v-btn elevation="0" class="back-btn mb-6" @click="navigateToPrevious">
                <v-icon left>mdi-keyboard-backspace</v-icon>
                Back to previous step
            </v-btn>
            <div class="d-flex">
                <div class="mr-7">
                    <span class="subtitle-1">Voiceform length</span>
                    <v-select :items="questionsLengthList" v-model="lengthSelector" style="width: 200px"
                        outlined dense>
                        <template slot="item" slot-scope="data">
                            {{ data.item }} questions
                        </template>
                        <template slot="selection" slot-scope="data">
                            {{ data.item }} questions
                        </template>
                    </v-select>
                </div>
                <div>
                    <span class="subtitle-1">What are you creating?</span>
                    <v-select :items="useTypes" v-model="useTypeSelector" item-text="useType" item-value="code" style="width: 250px" outlined dense></v-select>
                </div>
            </div>
            <div class="subtitle-1">
                What is your goal for this voiceform?
            </div>
            <div class="body-1">
                Remember to be as descriptive and specific as possible. The better the description the better generated result 🧠
            </div>
            <v-textarea class="mt-5 mb-0" outlined no-resize rows="10" v-model="goalSelector"
                placeholder="Example: collect customer feedback and identify what our customers like about our products and services, and what they don't like. We would like to understand what we can do to provide a better customer experience that keeps them coming back and working with us."></v-textarea>
            <div class="d-flex mb-7 justify-space-between" style="margin-top: -30px;">
                <span class="body-2">minimum 3 words</span>
                <span class="body-2">{{ formGoalLength }}/100 words</span>
            </div>
            <v-btn color="primary" @click="generate" :disabled="!canGoToNextStep || loading">
                Continue
            </v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from "vuex";
import useTypes from '../../../configs/useTypes.js'
export default {
    props: {
        navigateToNext: { type: Function },
        navigateToPrevious: { type: Function }
    },
    data: () => ({
        questionsLengthList: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        useTypes: useTypes,
        loading: false,
    }),
    computed: {
        ...mapGetters({
            length: 'aiFormGeneration/length',
            goal: 'aiFormGeneration/goal',
            useType: 'aiFormGeneration/useType',
            businessUseCase: 'aiFormGeneration/businessUseCase',
        }),
        formGoalLength() {
            return this.goal.split('\n').join(' ').split(' ').filter(function (n) { return n != '' }).length;
        },
        canGoToNextStep() {
            return this.formGoalLength >= 3 && this.formGoalLength <= 100;
        },
        goalSelector: {
            get() {
                return this.goal;
            },
            set(val) {
                this.setGoal(val);
            }
        },
        lengthSelector: {
            get() {
                return this.length;
            },
            set(val) {
                this.setLength(val);
            }
        },
        useTypeSelector: {
            get() {
                return this.useType;
            },
            set(val) {
                this.setUseType(val);
            }
        },
    },
    methods: {
        ...mapMutations({
            showSnackBar: 'showSnackBar',
            setUseType: 'aiFormGeneration/setUseType',
            setGoal: 'aiFormGeneration/setGoal',
            setLength: 'aiFormGeneration/setLength',
            setResult: 'aiFormGeneration/setResult',
        }),
        async generate() {
            if (!this.canGoToNextStep) {
                return;
            }
            this.loading = true;
            try {
                const { data } = await axios.post('/api/ai-forms/generate', { businessUseCase: this.businessUseCase, length: this.length, useType: this.useType, goal: this.goal });
                this.setResult(data)
                window.heap.track('added-survey-goal-description', {
                    'businessUseCase': this.businessUseCase,
                    'survey_goal': this.goal,
                    'survey_goal_length': this.formGoalLength,
                    'survey_length': this.length,
                    'useType': this.useType,
                });
                this.navigateToNext();
            } catch (e) {
                window.heap.track('ai-failed-to-create-survey');
                this.showSnackBar({
                    text: "Something went wrong. Can't regenerate survey. Please try again with different data or contact our support team.",
                    color: 'error',
                    timeout: 2000
                })
            }
            this.loading = false;
        }
    }
}
</script>

<style scoped>
::v-deep .back-btn {
    background-color: transparent !important;
    color: #0066FF;
}
</style>