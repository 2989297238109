<template>

    <div class="px-4 py-2 mx-2" >
        <div class="d-flex align-center mb-4">
            <div class="text-subtitle-1 mr-4">{{membership.team.memberships.length}} Members</div>
            <invite-members v-if="membership.role !== 'member'" :membership="membership"></invite-members>
        </div>
        <table class="table">
            <thead>
                <th class="text-subtitle-2 grey--text text--darken-1">Email</th>
                <th class="text-subtitle-2 grey--text text--darken-1">Role</th>
                <th class="text-subtitle-2 grey--text text--darken-1">Status</th>
                <th v-if="membership.role !== 'member'">&nbsp;</th>
            </thead>
            <tbody>
                <member-item v-for="member in membership.team.memberships" :member="member" :key="member.id" :membership="membership"></member-item>
            </tbody>
        </table>
    </div>
</template>

<script>
import InviteMembers from './InviteMembers.vue'
import MemberItem from './MemberItem.vue'
export default {
    components: {MemberItem, InviteMembers},
    props: {
        membership: {
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.table{
    text-align: left;
    width: 100%;
    border-collapse: collapse;
}
</style>