<template>
    <v-dialog v-model="show" width="800">
        <v-card class="pa-8">
            <div class="d-flex flex-grow-1">
                <h5 class="text-h5">
                    Generate voiceform
                </h5>
                <v-spacer></v-spacer>
                <v-btn class="mr-n4" @click="show = false" text color="primary">Close</v-btn>
            </div>
            <div class="mt-8 d-flex ">
                <v-stepper class="flex-grow-1 elevation-0" v-model="currentStep">
                    <v-stepper-header class="elevation-0 mb-5">
                        <v-stepper-step :complete="currentStep > 1" step="1">
                            Business
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="currentStep > 2" step="2">
                            Form Goal
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">
                            Generate
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <business-step :navigateToNext="navigateToNext"> </business-step>    
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <form-goal-step :navigateToNext="navigateToNext" :navigateToPrevious="navigateToPrevious"></form-goal-step>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <generate-step :navigateToPrevious="navigateToPrevious"></generate-step>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import BusinessStep from './AiFormSteps/BusinessStep.vue'
import FormGoalStep from './AiFormSteps/FormGoalStep.vue'
import GenerateStep from './AiFormSteps/GenerateStep.vue'

export default {
    props: ['visible'],
    components: {
        BusinessStep, FormGoalStep, GenerateStep
    },
    data() {
        return {
            currentStep: 1,
        }
    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close');
                    this.clear();
                    this.currentStep = 1;
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            clear: 'aiFormGeneration/clear',
        }),
        navigateToNext() {
            if (this.currentStep >= 3) {
                return;
            }
            this.currentStep++;
        },
        navigateToPrevious(){
            if (this.currentStep <= 1) {
                return;
            }
            this.currentStep--;
        }
    }
}
</script>

<style scoped>
.v-stepper__content {
    padding: 0px;
}

.v-stepper__step {
    padding-left: 0px;
    padding-right: 0px;
}



.v-stepper .v-stepper__header .v-divider {
    margin: 0 15px;
}
</style>