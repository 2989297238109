<template>
    <v-alert
        class="mt-4 alert"
        color="grey darken-1"
        outlined
        dense
    >
        <div class="d-flex align-center">
            <div class="d-flex flex-column">
                <div class="text-subtitle-1 mb-4 error--text text--darken1">
                    Delete your team
                </div>
                <div class="body-2 mr-4 mb-4">
                    If you decide to delete this team, all members and voiceform data related to this team will be permanently deleted, and cannot be recovered.
                </div>
            </div>
            <v-btn @click="dialog=true" outlined color="error">
                Remove Team
            </v-btn>
        </div>
        <v-dialog v-model="dialog" width="450">
            <v-card class="d-flex pa-6 align-center justify-center flex-column">
                <div class="text-subtitle-1 mb-4 text-center">Are you sure you want to remove this team?</div>
                <div class="d-flex">
                    <v-btn @click="handleTeamLeaving" :loading="loading" :disabled="loading" elevation="0" color="primary" class="">
                        Yes, I am sure
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-alert>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    data(){
        return {
            dialog: false,
            loading: false
        }
    },
    props: ['membership'],
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            removeTeam: 'teams/removeTeam'
        }),
        async handleTeamLeaving(){
            try {
                this.loading = true
                const res = await this.removeTeam(this.membership.team.id)
                this.loading = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: `The team has been removed.`,
                    });
                    this.dialog = false
                    this.$emit('removed')
                }
            } catch (error) {
                console.error(error);
                this.loading = false
                let errorText = 'Oooops, something went wrong. Cannot remove this team. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
            
        }
    }
}
</script>

<style scoped>
.alert{
    border: dashed thin var(--v-error-darken1) !important;
}
</style>