<template>
    <div>
        <div class="d-flex align-center justify-space-between">
            <div class="text-h6">Teams</div>
           <create-team></create-team>
        </div>

        <div class="overflow-y-auto pt-4">
             <v-hover v-for="membership in memberships"
                    :key="membership.id" v-slot="{ hover }">
                <v-card 
                    :color="activeMembership===membership.id? 'primary lighten-5': 'grey lighten-4'"
                    class="fill-width mb-4 py-2 px-3" 
                    elevation="0"
                    @click="activeMembership=membership"
                    :ripple="false"
                    outlined
                >
                    <div class="d-flex align-center">
                        <v-avatar class="mr-3" size="35" color="primary lighten-4" left>
                            <img
                                v-if="membership.team.icon_url"
                                :src="membership.team.icon_url"
                                alt="team icon"
                            >
                            <span v-else>{{membership.team.name.toUpperCase()[0]}}T</span>
                        </v-avatar>
                        <div class="d-flex flex-column align-start flex-grow-1 team-context-container">
                            <div class="text-subtitle-2 ellipsis-text">{{membership.team.name}}</div>
                            <div :class="['caption', {'error--text':membership.status==='blocked'}]">{{membership.status==='blocked'?'Blocked':membership.role}}</div>
                        </div>
                        <team-settings :membership="membership" :show-button="hover"></team-settings>
                    </div>
                </v-card>
            </v-hover>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateTeam from '../views/teams/CreateTeam.vue'
import TeamSettings from '../views/teams/TeamSettings.vue'
export default {
    components: { CreateTeam, TeamSettings },
    computed: {
        ...mapGetters({
            memberships: 'teams/memberships',
            user: 'auth/user'
        }),

        activeMembershipFromRoute(){
            return this.$route.params.teamId ? this.memberships.find(membership => membership.team_id === this.$route.params.teamId) : null
        },

        activeMembership: {
            get(){
                return this.activeMembershipFromRoute?.id || this.user.active_membership_id
            },
            set(membership){
                this.updateActiveMembership(membership.id)
                this.$router.push({name: 'dashboard.index', params: {teamId: membership.team.id}})
            }
        }
    },
    methods: {
        ...mapActions({
            updateActiveMembership: 'auth/updateActiveMembership'
        })
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.team-context-container{
    min-width: 0;
}
</style>