<template>
    <div>
        <v-btn v-if="showButton" class="ml-2" icon @click.stop="editTeamDialog=true">
            <v-icon small>mdi-cog-outline</v-icon>
        </v-btn>
        <v-dialog 
            v-model="editTeamDialog"
            width="600"
            persistent
            no-click-animation
        >
            <v-card class="py-6">
                <div class="d-flex mb-5 px-6">
                    <div class="text-h6 ">
                        Team settings
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn @click="editTeamDialog=false" icon><v-icon>mdi-close</v-icon></v-btn>
                </div>
                <v-divider class="my-4"></v-divider>
                <edit-team :membership="membership"></edit-team>
                <team-members :membership="membership"></team-members>
                <div class="mt-6 mx-6">
                    <destroy-team v-if="membership.role==='owner'" @removed="editTeamDialog=false" :membership="membership"></destroy-team>
                    <leave-team v-else @left="editTeamDialog=false" :membership="membership"></leave-team>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DestroyTeam from './DestroyTeam.vue'
import EditTeam from './EditTeam.vue'
import LeaveTeam from './LeaveTeam.vue'
import MemberItem from './MemberItem.vue'
import TeamMembers from './TeamMembers.vue'
export default {
    props: {
        showButton: {
            type: Boolean,
            required: true
        },
        membership: {
            required: true
        }
    },
    components: {MemberItem, TeamMembers, EditTeam, LeaveTeam, DestroyTeam},
    data(){
        return {
            editTeamDialog: false,
        }
    }
}
</script>