<template>
    <div elevation="0" class="px-4 py-2 mx-2 mb-4" >
        <div class="d-flex align-center mb-4">
            <div class="text-subtitle-1">Team Info</div>
            <v-btn v-if="membership.role !== 'member'" @click="handleDialogOpen" class="ml-4" small outlined color="primary">
                <v-icon small left>mdi-pencil</v-icon>
                Edit
            </v-btn>
        </div>
        <div class="d-flex">
            <v-avatar size="60" color="primary lighten-4 mr-3" left>
                <img
                    v-if="membership.team.icon_url"
                    :src="membership.team.icon_url"
                    alt="team icon"
                >
                <span v-else>{{membership.team.name.toUpperCase()[0]}}T</span>
            </v-avatar>
            <div>
                <div class="text-h6">{{membership.team.name}}</div>
                <div class="text-body-1 mt-1">
                    {{membership.team.description || '--'}}
                </div>
            </div>
        </div>
        <v-dialog 
            v-model="updateTeamInfoDialog"
            width="500"
        >
            <v-card class="py-6">
                <div class="text-h6 mb-5 px-6">
                    Update Team Info
                </div>
                <v-divider class="my-4"></v-divider>
                
                <v-form
                    ref="form"
                    lazy-validation
                    v-model="valid"
                    @submit.prevent="handleSubmit"
                    class="px-6 mb-6"
                    autocomplete="off"
                >
                    <div class="d-flex">
                        <team-icon-selector v-model="iconUrl"></team-icon-selector>
                        <div class="d-flex flex-column flex-grow-1 ml-4">
                            <div class="text-subtitle-1 mb-2">Team name</div>
                            <v-text-field 
                                outlined 
                                dense 
                                placeholder="Marketing"  
                                v-model="name"
                                :rules="nameRules"
                                validate-on-blur
                                counter="30"
                            ></v-text-field>
                        </div>
                    </div>

                    <div class="text-subtitle-1 mb-2">Team description (optional)</div>
                    <div class="text-body-2 mb-4">Share a little bit about your team and goals!</div>
                    <v-textarea 
                        outlined 
                        dense 
                        placeholder="A team of superstar marketers conducting research about consumer behavior."  
                        v-model="description"
                        validate-on-blur
                        counter="100"
                        :rules="descriptionRules"
                    ></v-textarea>
                </v-form>
                <div class="d-flex pt-4 px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="updateTeamInfoDialog=false"
                        class="mr-4"
                        v-if="!submittingForm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        :elevation="0"
                        @click="handleUpdateTeam"
                        :loading="submittingForm"
                        :disabled="submittingForm"
                    >
                        Update
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import TeamIconSelector from './TeamIconSelector.vue';
export default {
    components: { TeamIconSelector },
    props: ['membership'],
    data(){
        return {
            updateTeamInfoDialog: false,
            valid: true,
            name: '',
            description: '',
            submittingForm: false,
            iconUrl: '',
            nameRules: [
                value => !!value.trim() || 'The name is required',
                value => value.trim().length<=30 || 'The team name is too long'
            ],
            descriptionRules: [
                value => value.trim().length<=100 || 'The team description is too long'
            ]
        }
    },
    methods: {
        ...mapActions({
            updateTeam: 'teams/updateTeam'
        }),
        ...mapMutations(['showSnackBar']),
        handleDialogOpen(){
            this.name= this.membership.team && this.membership.team.name || ''
            this.description= this.membership.team && this.membership.team.description || ''
            this.iconUrl = this.membership.team && this.membership.team.icon_url || ''
            this.updateTeamInfoDialog = true
        },
        async handleUpdateTeam(){
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }
                this.submittingForm = true
                const res = await this.updateTeam({
                    teamId: this.membership.team.id,
                    data: {
                        name: this.name.trim(),
                        description: this.description.trim(),
                        icon_url: this.iconUrl
                    }
                })
                this.submittingForm = false
                
                if(res && res.status ===200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: "The team is updated!",
                    });

                    this.updateTeamInfoDialog = false
                }
            } catch (error) {
                this.submittingForm = false

                let errorText = 'Ops. Something went wrong. Please try to submit the form later or contact customer support.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    text: errorText,
                    color: 'error', 
                    timeout: 4000
                })
            }
        }
    }
}
</script>