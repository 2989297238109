<template>
    <v-alert
        class="mt-4"
        color="grey darken-1"
        outlined
        dense
    >
        <div class="d-flex align-center">
            <div class="d-flex flex-column">
                <div class="text-subtitle-1 mb-4">
                    Remove Membership
                </div>
                <div class="body-1 mb-4">
                    Once you leave the team there is no going back. Please be certain. 
                </div>
            </div>
            <v-btn @click="dialog=true" outlined color="error">
                Leave Team
            </v-btn>
        </div>
        <v-dialog v-model="dialog" width="400">
            <v-card class="d-flex pa-6 align-center justify-center flex-column">
                <div class="text-subtitle-1 mb-4">Are you sure you want to leave this team?</div>
                <div class="d-flex">
                    <v-btn @click="handleTeamLeaving" :loading="loading" :disabled="loading" elevation="0" color="primary" class="">
                        Yes, I am sure
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-alert>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    data(){
        return {
            dialog: false,
            loading: false
        }
    },
    props: ['membership'],
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            removeMembership: 'teams/removeMembership'
        }),
        async handleTeamLeaving(){
            try {
                this.loading = true
                const res = await this.removeMembership(this.membership.id)
                this.loading = false
                if(res && res.status === 200){
                    this.showSnackBar({
                        color: "success",
                        timeout: 6000,
                        text: `You left the team.`,
                    });
                    this.dialog = false
                    this.$emit('left')
                }
            } catch (error) {
                console.error(error);
                this.loading = false
                let errorText = 'Oooops, something went wrong. Cannot leave this team. Please try again later.'
                if(error.response && error.response.data && error.response.data.message){
                    errorText=error.response.data.message
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: errorText,
                });
            }
            
        }
    }
}
</script>