export const icons = {
    'Team Types' : [
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/marketing.svg',
            alt: 'marketing team icon'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/support.svg',
            alt: 'support team icon'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/design.svg',
            alt: 'design team icon'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/operations.svg',
            alt: 'operations team icon'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/dev.svg',
            alt: 'development team icon'   
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/hr.svg',
            alt: 'hr icon'   
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/types/finance.svg',
            alt: 'finance team icon'   
        }
    ],
    business: [
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/001-wristwatch.svg',
            alt: 'wristwatch'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/002-apartment.svg',
            alt: 'apartment'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/003-atm.svg',
            alt: 'atm'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/004-auction.svg',
            alt: 'auction'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/005-briefcase.svg',
            alt: 'briefcase'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/006-calculator.svg',
            alt: 'calculator'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/007-champion.svg',
            alt: 'champion'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/008-coin.svg',
            alt: 'coin'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/009-contract.svg',
            alt: 'contract'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/011-exchange.svg',
            alt: 'exchange'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/012-luxury.svg',
            alt: 'luxury'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/013-clipboard.svg',
            alt: 'clipboard'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/015-website.svg',
            alt: 'website'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/016-contract.svg',
            alt: 'contract'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/017-agreement.svg',
            alt: 'agreement'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/018-hierarchy.svg',
            alt: 'hierarchy'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/019-structure.svg',
            alt: 'structure'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/021-idea.svg',
            alt: 'idea'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/022-investment.svg',
            alt: 'investment'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/023-line%20chart.svg',
            alt: 'line chart'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/024-mail.svg',
            alt: 'mail'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/025-money.svg',
            alt: 'money'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/026-monitor.svg',
            alt: 'monitor'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/027-network.svg',
            alt: 'network'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/029-pie%20chart.svg',
            alt: 'pie chart'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/030-statistics.svg',
            alt: 'statistics'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/031-statistics.svg',
            alt: 'statistics'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/032-presentation.svg',
            alt: 'presentation'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/033-rubber%20stamp.svg',
            alt: 'rubber stamp'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/034-search.svg',
            alt: 'search'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/035-folder.svg',
            alt: 'folder'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/036-stats.svg',
            alt: 'stats'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/037-tie.svg',
            alt: 'tie'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/038-wallet.svg',
            alt: 'wallet'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/business/040-worldwide.svg',
            alt: 'worldwide'
        },

    ],
    food: [
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/011-burger%20sandwich.svg',
            alt: 'burger sandwich'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/068-tacos.svg',
            alt: 'tacos'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/054-pizza.svg',
            alt: 'pizza'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/026-doughnut.svg',
            alt: 'doughnut'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/037-hot%20dog.svg',
            alt: 'hot dog'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/052-pasta.svg',
            alt: 'pasta'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/067-sushi.svg',
            alt: 'sushi'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/058-ramen.svg',
            alt: 'ramen'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/027-dumplings.svg',
            alt: 'dumplings'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/042-maple%20syrup.svg',
            alt: 'maple syrup'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/001-afternoon%20tea.svg',
            alt: 'afternoon tea'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/002-baguette.svg',
            alt: 'baguette'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/003-baklava.svg',
            alt: 'baklava'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/004-banana%20bread.svg',
            alt: 'banana bread'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/005-barbecue.svg',
            alt: 'barbecue'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/006-bibimbap.svg',
            alt: 'bibimbap'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/007-boba.svg',
            alt: 'boba'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/008-borscht.svg',
            alt: 'borscht'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/009-brioche.svg',
            alt: 'brioche'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/010-bruschetta.svg',
            alt: 'bruschetta'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/012-burrito.svg',
            alt: 'burrito'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/013-chicken%20rice.svg',
            alt: 'chicken rice'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/015-churros.svg',
            alt: 'churros'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/016-chutney.svg',
            alt: 'chutney'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/017-clam%20chowder.svg',
            alt: 'clam chowder'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/018-coriander.svg',
            alt: 'coriander'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/019-cornbread.svg',
            alt: 'cornbread'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/020-coxinha.svg',
            alt: 'coxinha'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/021-croissant.svg',
            alt: 'croissant'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/022-cuban%20sandwich.svg',
            alt: 'cuban sandwich'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/023-curry.svg',
            alt: 'curry'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/024-deviled%20eggs.svg',
            alt: 'deviled eggs'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/028-empanada.svg',
            alt: 'empanada'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/029-enchilada.svg',
            alt: 'enchilada'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/030-falafel.svg',
            alt: 'falafel'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/031-fish%20and%20chips.svg',
            alt: 'fish and chips'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/033-fried%20chicken.svg',
            alt: 'fried chicken'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/034-goulash.svg',
            alt: 'goulash'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/035-cheese.svg',
            alt: 'cheese'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/036-guacamole.svg',
            alt: 'guacamole'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/038-japanese%20food.svg',
            alt: 'japanese food'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/039-kebab.svg',
            alt: 'kebab'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/040-kimchi.svg',
            alt: 'kimchi'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/041-lasagna.svg',
            alt: 'lasagna'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/043-masala%20dosa.svg',
            alt: 'masala dosa'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/044-mochi.svg',
            alt: 'mochi'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/045-nachos.svg',
            alt: 'nachos'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/046-nasi%20goreng.svg',
            alt: 'nasi goreng'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/047-onigiri.svg',
            alt: 'onigiri'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/048-pad%20thai.svg',
            alt: 'pad thai'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/049-paella.svg',
            alt: 'paella'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/050-palm%20oil.svg',
            alt: 'palm oil'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/051-parfait.svg',
            alt: 'parfait'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/053-pavlova.svg',
            alt: 'pavlova'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/055-poke.svg',
            alt: 'poke'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/056-poutine.svg',
            alt: 'poutine'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/057-pretzel.svg',
            alt: 'pretzel'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/059-ratatouille.svg',
            alt: 'ratatouille'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/060-saffron.svg',
            alt: 'saffron'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/061-Sake.svg',
            alt: 'Sake'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/062-sarma.svg',
            alt: 'sarma'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/063-satay.svg',
            alt: 'satay'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/064-spaghetti.svg',
            alt: 'spaghetti'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/065-spring%20rolls.svg',
            alt: 'spring rolls'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/066-sugar%20cane.svg',
            alt: 'burger cane'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/072-tteokbokki.svg',
            alt: 'tteokbokki'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/073-wasabi.svg',
            alt: 'wasabi'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/074-sausage.svg',
            alt: 'sausage'
        },
        {
            url: 'https://cdn.voiceform.com/media/teams-icons/food/075-yucca.svg',
            alt: 'yucca'
        },
        
    ]
}