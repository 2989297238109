<template>
    <div>
        <div class="text-h6 mb-5">
            Tell us about your business or organization and the products and services you offer?
        </div>
        <div class="body-1 mb-5">
            Remember to be as descriptive and specific as possible. The better the description the better generated result 🧠
        </div>
        <v-textarea class="mt-5 mb-0" clearable outlined no-resize rows="10" v-model="businessUseCaseSelector"
            placeholder="Example: XYZ Fitness is a premium gym that offers personalized training and group fitness classes to help individuals achieve their fitness goals. Our state-of-the-art facility includes cardio machines, weightlifting equipment, and a variety of fitness classes such as yoga, Pilates, and spin. Our certified personal trainers work with clients to develop customized workout plans and provide ongoing support and motivation. At XYZ Fitness, we are committed to helping our clients improve their health and wellness in a supportive and inspiring environment."></v-textarea>
        <div class="d-flex mb-7 justify-space-between" style="margin-top: -30px;">
            <span class="body-2">minimum 30 words</span>
            <span class="body-2">{{ businessUseCaseLength }}/100 words</span>
        </div>
        <v-btn color="primary" @click="saveAndContinue" :loading="loading" :disabled="!canGoToNextStep || loading"
            elevation="0">
            Continue
        </v-btn>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    props: {
        navigateToNext: { type: Function }
    },
    data: function () {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            activeMembership: 'teams/activeMembership',
            businessUseCase: 'aiFormGeneration/businessUseCase',
        }),
        businessUseCaseLength() {
            return this.businessUseCase && this.businessUseCase.split('\n').join(' ').split(' ').filter(function (n) { return n != '' }).length || 0;
        },
        canGoToNextStep() {
            return this.businessUseCaseLength >= 30 && this.businessUseCaseLength <= 100;
        },
        businessUseCaseSelector: {
            get() {
                return this.businessUseCase;
            },
            set(val) {
                this.setBusinessUseCase(val);
            }
        },
    },
    methods: {
        ...mapActions({
            updateTeam: 'teams/updateTeam'
        }),
        ...mapMutations({
            setBusinessUseCase: 'aiFormGeneration/setBusinessUseCase',
            showSnackBar: 'showSnackBar',
        }),
        async saveAndContinue() {
            try {
                if (!this.canGoToNextStep) {
                    return;
                }
                this.loading = true;
                await this.updateTeam({
                    teamId: this.activeMembership.team.id,
                    data: {
                        ...this.activeMembership.team,
                        business_use_case: this.businessUseCase
                    }
                });
                this.loading = false;
                window.heap.track('updated-business-description', {
                    'team_id': this.activeMembership.team.id,
                    'business_use_case': this.businessUseCase,
                    'business_use_case_words_length': this.businessUseCaseLength,
                });
                this.navigateToNext();
            } catch (e) {
                this.loading = false;
                this.showSnackBar({
                    text: "Something went wrong. Please try again later or contact our support team",
                    color: 'error',
                    timeout: 2000
                })
            }
        }
    },
    mounted() {
        try {
            this.setBusinessUseCase(this.activeMembership.team.business_use_case);
        } catch (e) {
            this.showSnackBar({
                text: "Something went wrong. Please try again later or contact our support team",
                color: 'error',
                timeout: 2000
            })
        }
    },
}
</script>