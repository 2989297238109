<template>
    <div>
        <v-btn elevation="0" class="back-btn mb-6" @click="navigateToPrevious">
            <v-icon left>mdi-keyboard-backspace</v-icon>
            Back to previous step
        </v-btn>
        <div class="text-h6 mb-6">Preview</div>
        <div>
            <ul>
                <li v-for="(item, index) in result" :key="`generated-question-${index}`">
                    <div class="d-flex justify-space-between">
                        <div>
                            <span class="text-body-1">{{ item.question }}</span>
                        </div>
                        <div>
                            <v-chip class="type">{{ item.type }}</v-chip>
                        </div>
                    </div>
                    <div v-if="item.options && item.options.length">
                        <span class="text-body-2">Options:</span>
                        <v-chip v-for="option in item.options" class="option" :key="option">
                            {{ option }}
                        </v-chip>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-3" style="display: flex; flex-direction: row-reverse;">
            <v-btn @click="submit" color="primary" :disabled="loading" :loading="loading" elevation="0">
                Create & Start Editing
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios';

export default {
    props: {
        navigateToPrevious: { type: Function }
    },
    computed: {
        ...mapGetters({
            result: 'aiFormGeneration/result',
            defaultSurvey: 'survey/defaultSurvey',
            activeMembership: 'teams/activeMembership',
            length: 'aiFormGeneration/length',
            goal: 'aiFormGeneration/goal',
            useType: 'aiFormGeneration/useType',
            businessUseCase: 'aiFormGeneration/businessUseCase',
        }),
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        ...mapMutations({
            clear: 'aiFormGeneration/clear',
        }),
        async submit() {
            this.loading = true;
            const { data } = await axios.post('/api/ai-forms/create', { survey: this.defaultSurvey, result: this.result, membership_id: this.activeMembership.id, length: this.length, goal: this.goal, use_type: this.useType, business_use_case: this.businessUseCase});
            const surveyId = data?.data?.id;
            this.loading = false;
            window.heap.track('generated-form');
            _cio.track("create_form.success", {
                form_id: surveyId,
                form_hash: data?.data?.hash,
            })
            this.clear();
            this.$router.push({
                name: 'dashboard.voiceform.show',
                params: {
                    surveyId: surveyId
                }
            });
        }
    }
}
</script>

<style scoped>
::v-deep .back-btn {
    background-color: transparent !important;
    color: #0066FF;
}

.type {
    color: #2341E0;
    background: linear-gradient(0deg, rgba(0, 102, 255, 0.08), rgba(0, 102, 255, 0.08)), #FFFFFF !important;
}

.option {
    color: #4CAF50;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50 !important;
    border-radius: 4px;
    padding: 4px 12px;
    margin-right: 4px;
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

li {
    list-style-type: decimal;
    line-height: 35px;
    margin-bottom: 20px;
}
</style>